import { Component, OnInit } from '@angular/core';
import { ViewChild,ElementRef } from '@angular/core';
import { NetworkService } from './../network.service';
import { Observable, of} from 'rxjs';
import {ENGINE_URL} from '../app.constants';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  urlPrefix: string;
  constructor(private network: NetworkService) {
    this.copyrightYear= new Date().getFullYear();
    this.urlPrefix = environment.urlPrefix;
  }
  @ViewChild('loginRef', { static: true })
  loginElement!: ElementRef;
  auth2:any;
  show:any;
  Name: any;
  title = 'control_center_wrapper';
  copyrightYear: any;

  ngOnInit(): void {
    this.googleInitialize();
  }

  googleInitialize() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          // client_id: '24896139014-ea16v0ld3keuop496i6d46ij1ed3be8b.apps.googleusercontent.com',
          client_id: environment.googleClientID,
          cookie_policy: 'single_host_origin',
          scope: 'profile email'
        });
        this.prepareLogin();
      });
    }
    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode!.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));
  }

  prepareLogin() {
    this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        // console.log('Token || ' + googleUser.getAuthResponse().id_token);
        this.show = true;
        this.Name =  profile.getName();
        // console.log('Image URL: ' + profile.getImageUrl());
        // console.log('Email: ' + profile.getEmail());
        const param= {
          email: profile.getEmail(),
          avatar: profile.getImageUrl(),
          token: googleUser.getAuthResponse().id_token
        };
        
        this.network.postData(ENGINE_URL+'socialLogin/storeSystemUsers', param).subscribe((result) => {  
        // console.log(result.data.user_roles);
        this.post(this.urlPrefix, {username: profile.getEmail(), token: result.data.token, roles: result.data.user_roles, avatar: result.data.record.extra_details.avatar});
        // console.log(result.data.record.extra_details);
      });
      }, (error) => {
        console.log(error.error);        
        if(error.error=="popup_closed_by_user")
        {
          alert("Google Signin Popup Closed");
        }
        else
        {
          alert(JSON.stringify(error, undefined, 2));
        }
      });
  }

  post(path, params, method='post') {
    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];  
        

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }




}
