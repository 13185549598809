// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlPrefix: 'https://controlcenter-prelive.solar.com/login.php',
  // urlPrefix: 'http://localhost/controlcenter/login.php',
  // googleClientID: '615413895619-6rjne4u15mb2lkumgo6j8gcsnvlu4fel.apps.googleusercontent.com'   //This is Kashif's client ID, placed here for testing purposes
  googleClientID: '677856266838-3b2nojp2k9h6er2ndc943fonie9i8jdj.apps.googleusercontent.com',      //The client ID provided by Prasenjit (The genral one)
  engineEndpoint: 'https://engine-k8s-test.solar.com/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
